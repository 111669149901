/* ADD CSS STYLING HERE */
/* Content is rendered within a div below the header */
/* Overflowing content will automatically scroll within the div */

.story-content{
    margin: 15px auto;
    padding: 10px;
    height: 80%;
    width: 80%;
    box-sizing: border-box;
    background-color: rgb(185, 185, 185);
}