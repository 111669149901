.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* body {
    width: 100vw;
    height: 100vh;
    background-image: var(--asset-background_image);
    background-repeat: no-repeat;
    background-size: cover;
} */

.Container {
  /* transition: width 1s, transform 2s; */
  transition: transform 0.225s;
  transition-timing-function: ease-in-out;
  /* width: 100vw; */
  /* height: 100vh; */
  /* position: 'absolute';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
  /* min-height: 1080px; */
  /* background-image: url('../public/images/bg.jpg'); */
  /* background-image: var(--asset-background_image); */
  /* background-repeat: no-repeat; */
  /* background-position: center; */
  /* background-size: cover; */
  /* background-size: auto; */
}



.logo{
  background-image: var(--asset-logo);
  background-repeat: no-repeat;
  background-position: center;
}

.fadeOut {
  opacity:0;
  transition: opacity 1s linear;
}
.fadeIn {
  opacity:1;
}

.Background{
  /* width: 100%;
  height: 100%; */

  /* background: (url) fixed center no-repeat; margin:0 auto; height:auto */
  /* background-color: black; */
  background-image: var(--asset-background_image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  /* background-size: cover; */
  background-attachment: fixed;
  /* background-position: left top; */
  position:absolute;
  margin: 0 auto;  
  height: auto;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.fullscreen {
  z-index: 100;
}

.navDrawer {
  z-index: 1;
}

.carousel-container {
  width: 100%;
  height: calc(100vw * 0.8);
}

/* .MuiDrawer-root .MuiDrawer-docked{
  width: 0px;
  background-color: #282c34;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
