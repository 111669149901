body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* width: 100vw;
  height: 100vh;
  background-image: var(--asset-background_image);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed; */
}

.logoNav{
  background-image: var(--asset-logo);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  margin: 0 auto;
  margin-bottom: 64px;
  transition: transform 0.225s;
  transition-timing-function: ease-in-out;
}

.logo {
  position: absolute;
  top: 0;
  right: 0;
  width: 256;
  height: 256;
  margin: 32px;
  background-image: var(--asset-logo);
  background-repeat: no-repeat;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
